//Navigation
var hnav = $('#h-nav'),
    hnavPos = hnav.offset().top,
    hnavList = $('#h-n-list'),
    hnavToggle = $('#h-n-b-toggler');
function navCalc(){
  var scrValue = $(this).scrollTop(),
      hnavInner = hnav.innerHeight();
  $('#h-n-b-toggler, .nav-item').removeClass('active');
  hnavList.removeAttr('style');
  if(scrValue > hnavPos){
    hnav.addClass('h-navfixed');
  }else{
    hnav.removeClass('h-navfixed');
    if(hnavList.css('display') == 'none'){
      hnavList.css('top', hnavPos + hnavInner - scrValue + 'px');
    }
  }
}

jQuery(document).ready(function(){
  //NavigationControl
  navCalc();
  $(window).on('resize scroll', navCalc);
  hnavToggle.off('click');
  hnavToggle.on('click', function(){
    $(this).toggleClass('active');
    if($(this).hasClass('active')){
      hnavList.stop().slideDown(300, 'easeInOutQuad', function(){
        $(this).find('.nav-item').each(function(i){
          $(this).delay(90*i).queue(function(){ $(this).stop().addClass('active');});
        });
      });
    }else{
      hnavList.stop().slideUp(300, 'easeInOutQuad', function(){
        $(this).find('.nav-item').removeClass('active');
      });
    }
  });

  //Totop
  $('#f-totop').on('click', function(){
    $('html, body').stop().animate({scrollTop: 0}, 600, 'easeInOutQuad');
    return false;
  });

  //Rellax
  var intro = new Rellax('#h-i-i-plx', {speed: -16});
  var instructorImage = new Rellax('.i-l-i-plx', {speed: -0.7});

  //Swiper
  var instructorSwiper = new Swiper('#instructor', {
      autoHeight: true,
      centeredSlides: true,
      grabCursor: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      }
    });

  //Inview
  var i;
  $('#h-i-catch, #events, #instructor, #contact').on('inview', function(event, isInView, visiblePartX, visiblePartY){
    if(isInView){ $(this).addClass('anima');}
    // else{ $(this).removeClass('anima');}
  });
  $('#service, #works, #blog').on('inview', function(event, isInView, visiblePartX, visiblePartY){
    if(isInView){
      i = 0;
      $(this).addClass('anima');
      $(this).find('section').each(function(i){
        $(this).delay(180*i).queue(function(){ $(this).stop().addClass('anima');});
      });
    }
  });
  $('#banner').on('inview', function(event, isInView, visiblePartX, visiblePartY){
    if(isInView){
      i = 0;
      $(this).addClass('anima');
      $(this).find('li').each(function(i){
        $(this).delay(180*i).queue(function(){ $(this).stop().addClass('anima');});
      });
    }
  });
  $('#news').on('inview', function(event, isInView, visiblePartX, visiblePartY){
    if(isInView){
      i = 0;
      $(this).addClass('anima');
      $(this).find('article').each(function(i){
        $(this).delay(180*i).queue(function(){ $(this).stop().addClass('anima');});
      });
    }
  });

  //google map
  // var latlng = new google.maps.LatLng(33.589402, 130.388786),
  //     icon = { url: '../img/pin.png', scaledSize : new google.maps.Size(28, 40)},
  //     map = new google.maps.Map(document.getElementById('map'), {
  //       center: latlng,
  //       zoom: 17,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP,
  //       scrollwheel: false,
  //       mapTypeControl: false,
  //       streetViewControl: false
  //     }),
  //     marker = new google.maps.Marker({
	// 	    position: latlng,
	// 	    map: map,
  //       icon: icon,
  //       title: 'Neganin'
  //     }),
  //     styles = [{'stylers': [{'gamma': 0.8}, {'saturation': -100}, {'lightness': 20}]}],
  //     styleName = 'monochrome';
  // map.mapTypes.set(styleName, new google.maps.StyledMapType(styles, {name: styleName}));
  // map.setMapTypeId(styleName);
});
